export default `/* 全局属性
 * 页边距 padding: 30px;
 * 全文字体 font-family: ptima-Regular;
 * 英文换行 word-break: break-all;
 */

/*自定义样式，实时生效*/

#nice {
  
  line-height: 1.8em;
  letter-spacing: 0.1em;
}

/* 段落 */
#nice p {
  margin: 1.5em 5px !important;
  line-height: 1.8em;
  line-height: 1.6rem;
  word-spacing: .05rem;
  font-size: 14px;
}

/* 一级标题 */
#nice h1 {
  font-size: 26px !important;
  border-bottom: 1px solid #ddd !important;
}

/* 二级标题 */
#nice h2 {
  font-size: 18px !important;
  border-bottom: 1px solid #eee !important;
}

/* 三级标题 */
#nice h3 {
  font-size: 18px;
}

/* 无序列表整体样式 */
#nice ul {
  list-style-type: circle;
  padding-left: 15px;
}

/* 有序列表整体样式 */
#nice ol {
  padding-left: 10px;
  font-size: 14px;
}

/* 列表内容 */
#nice li section {
  margin: 10px;
  font-size: 14px;
}

#nice ol li {
    padding-left: 0.1rem;
}

/* 引用 */
#nice blockquote {
  border-left: 2px solid #009688;
  padding: 0 10px;
  color: #777;
}

/* 引用文字 */
#nice blockquote p {
}

/* 链接 */
#nice a {
}

/* 加粗 */
#nice strong {
  color: #BF360C;
}

/* 斜体 */
#nice em {
  color: #009688;
}

/* 删除线 */
#nice del {
}

/* 分隔线 */
#nice hr {
  border: 1px solid #BF360C;
  margin: 1.5em auto;
}

/* 图片 */
#nice img {
}

/* 图片描述文字 */
#nice figcaption {
}

/* 行内代码 */
#nice p code, #nice li code {
  font-size: 14px;
  font-family: Roboto, 'Courier New', Consolas, Inconsolata, Courier, monospace;
  white-space: pre-wrap;
  border-radius: 2px;
}

/* 非微信代码块 */
#nice pre code {
  font-size: 15px;
  line-height: 1.4em;
  white-space: pre;
  overflow: auto;
  border-radius: 3px;
  padding: 10px 10px;
}

/* 表格内的单元格 */
#nice table tr th,
#nice table tr td {
  font-size: 14px;
  border: 1px solid #CCC;
  margin: 0;
  padding: 5px 10px;
}

#nice .footnote-item p {
  margin:0!important
}

`;
